@use 'sass:math';
@import "~bootstrap/scss/bootstrap";

.nav-item {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 60px;
    border-color: #90A955 !important;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50% !important;
    position: relative;
    transition: transform .2s; /* Animation */

    img {
      width: 30px;
      height: 30px;

    }

    span {
      position: absolute;
      transform: translate(-50%, -50%);
      top: 50%;
      left: 0;
      width: 25px;
      height: 25px;
      background-color: #90A955;
      color: #111;
    }
  }

  a {
    color: #90A955;
    text-decoration: none;
    justify-content: center;
    align-items: center;
    position: relative;
    display: flex;
    flex-direction: column;
  }

  &:hover div {
    transform: scale(1.1); /* Aumenta el tamaño del elemento en un 10% al pasar el ratón por encima */
  }
}

.special-item {
  @extend .nav-item;

  div {
    border-color: #B8AA69 !important;

    span {
      background-color: #B8AA69;
    }
  }

  a {
    color: #B8AA69;
  }

}

ul .nav-item a:hover {
  color: #f4f4f4; /* Cambia a blanco cuando se pasa el mouse */

  div {
    border-color: #f4f4f4 !important;

    img {
      filter: brightness(0) invert(1);
    }

    span {
      color: #3c3c3c;
      background-color: #f4f4f4;
    }
  }

  p {
    text-shadow: none;
  }
}

.carousel-container {
  position: relative;
}

.top-border {
  transform: translateY(-33px);
}

.carousel-container {
  position: relative;
  overflow: hidden;
}

.parallax-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 2;
}

.learning-overlay {
  top: -40px;
  @extend .parallax-overlay
}

.parallax-content {
  z-index: 3;
}

.shape {
  filter: drop-shadow(2px 4px 6px #3c3c3c);
}

.shape-1 {
  @extend .shape;
  top: 0;
  left: 0;
  max-width: 330px !important;
}

.shape-2 {
  @extend .shape;
  top: 0;
  left: 0;
}

.shape-3 {
  @extend .shape;
  top: 0;
  right: 0;
}

.shape-4 {
  @extend .shape;
  top: 25%;
  right: 0;
}

.shape-5 {
  @extend .shape;
  top: 70%;
  left: -1px;
  width: 15%;
}

.bg-vms-dark {
  background-color: #4A4A49;
}

.bg-dark-body {
  background-color: #414042;
}

.bg-dark-body-light {
  background-color: #4e4e4e;
}

.news-button {
  background-color: #4A4A49;
}

.owl-stage {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.owl-item {
  transition: transform .2s;

  &:hover {
    transform: scale(1.1); /* Aumenta el tamaño del elemento en un 10% al pasar el ratón por encima */
  }
}

.owl-item .video-img {
  border: 3px solid white;
}

.grayscale-image {
  @include media-breakpoint-up(lg) {
    filter: grayscale(100%);
  }
}

.grayscale-image:hover {
  filter: none;
}

a:hover, a:focus {
  text-decoration: none;
  outline: none;
}

#news {
  .container {
    padding: 0 10%;
  }
}

#accordion .panel {
  border: none;
  border-radius: 0;
  box-shadow: none;
  margin: 0 20px 5px 0;
  position: relative;
}

#accordion .panel-heading {
  padding: 0;
  height: 70px;
  border: none;
  border-radius: 0;
  margin-bottom: 0;
  position: relative;
}

#accordion .panel-heading:before,
#accordion .panel-heading:after {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, #416342, #070707);
  position: absolute;
  top: 0;
}

#accordion .panel-heading:before {
  left: 0;
  transform: skew(-20deg, 0deg);
  transform-origin: left bottom 0;
}

#accordion .panel-heading:after {
  right: 0;
  transform: skew(20deg, 0deg);
  transform-origin: right bottom 0;
}

#accordion .panel-title a {
  display: block;
  padding: 15px 70px 15px 20px;
  margin: 0;
  background: #CABF8E;
  font-size: 18px;
  font-weight: 700;
  color: #006633;
  letter-spacing: 1px;
  border-radius: 0;
  position: relative;
  text-transform: uppercase;
}

#accordion .panel-title a.collapsed {
  background: linear-gradient(to bottom, #416342, #070707);
  color: #fff;
  text-transform: none;
}

#accordion .panel-title a:after,
#accordion .panel-title a.collapsed:after {
  content: "\f068";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  width: 55px;
  height: 100%;
  line-height: 50px;
  text-align: center;
  position: absolute;
  top: 0;
  right: 0;
}

#accordion .panel-title a.collapsed:after {
  content: "\f067";
}

#accordion .panel-body {
  position: relative;
  padding: 15px 20px;
  margin: 5px 20px 0;
  background: #CABF8E;
  border: none;
  outline-offset: -8px;
  font-size: 17px;
  color: #fff;
  line-height: 27px;
  z-index: 50;

  p {
    color: #1a1d20;
    padding: 0 10px;
    text-align: justify;
  }

  button {
    background-color: #90A955;
  }

  img {
    border: 6px solid #006633;
  }
}


#accordion .panel-collapse:before,
#accordion .panel-collapse:after {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  background: #CABF8E;
  position: absolute;
  top: 0;
  z-index: 0;
}

#accordion .panel-collapse:before {
  left: 0;
  transform-origin: left bottom 0;
  z-index: 0;

  @include media-breakpoint-down(md) {
    transform: skew(-1.9deg, 0deg);
  }

  @include media-breakpoint-up(lg) {
   transform: skew(-4.1deg, 0deg);
  }
}

#accordion .panel-collapse:after {
  right: 0;
  transform-origin: right bottom 0;
  z-index: 0;

  @include media-breakpoint-down(md) {
    transform: skew(1.9deg, 0deg);
  }

  @include media-breakpoint-up(lg) {
   transform: skew(4.1deg, 0deg);
  }
}

#accordion .panel-heading.collapsed .panel-title {
  visibility: hidden; /* Oculta el título cuando está expandido */
}

#accordion .panel-heading.collapsed .panel-title.m-0.h-100 {
  visibility: hidden; /* Oculta el título cuando está expandido */
}

.btn-read-more {
  width: 25%;

  @media screen and (max-width: 768px) {
    width: 100%;
  }
}

#images .filters {
  @include media-breakpoint-down(md) {
    padding: 0;
  }

  @include media-breakpoint-up(lg) {
    padding: 0 15%;
  }

  a {

    @include media-breakpoint-down(md) {
      width: 50px;
    }

    @include media-breakpoint-up(lg) {
      width: 130px;
    }

    padding: 2px 0 0 0;
    box-sizing: content-box;
    width: 130px;
    height: 3px;
    cursor: pointer;
    background-color: #fff;
    color: white;
    background-clip: padding-box;
    border: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    transition: opacity 0.6s ease;

    img {
      width: 50px;
    }
  }

  div {
    .active {
      background-color: #90A955 !important;
      color: #90A955 !important;

      img {
        filter: invert(50%) sepia(10%) saturate(2000%) hue-rotate(35deg) brightness(90%) contrast(85%);
      }
    }
  }
}

#learning .learning-items {
  padding: 0 1%;

  .learning-item {
    display: flex;
    flex-direction: column;

    h5 {
      color: white;
    }

    div {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      padding: 5% 5%;
      background-color: #244316;
      transition: transform .2s; /* Animation */

      .learning-icon {
        width: 120px;
        right: -55px;
        bottom: -40px;
      }
    }

    &:hover div {
      transform: scale(1.1); /* Aumenta el tamaño del elemento en un 10% al pasar el ratón por encima */
    }
  }
}

.w-userbar--bottom-right {
  bottom: 7em;
  right: 2em
}

.hover-grow {
  transition: transform .2s; /* Animation */
}

.hover-grow:hover {
  filter: drop-shadow(2px 4px 6px gray);
  transform: scale(1.1); /* (110% original size) */
}

.text-green {
  color: #006633 !important;
}

.contact-form {

  .form-group {
    background-color: #E4EAD6;
    color: #006633;
    padding: 1rem;
  }

  .form-control:focus {
    box-shadow: 0 0 0 2px rgba(144, 169, 85, 0.25)
  }
}

.transition {
  transition: opacity 0.5s;
}

.hidden {
  opacity: 0;
}

.video-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); /* Centra el icono */
  z-index: 2; /* Asegura que el icono está encima de la imagen */
}

.contact-text {
  padding: 12px 15%;
  line-height: 1.5;
}

.bg-green-light {
  background-color: #e9eedd !important;
}

.light-green-600 {
  color: #90a955 !important;
}

.top-40 {
  top: 40% !important;
}


.map-iframe {
  width: 100%;

  @include media-breakpoint-down(md) {
    height: 500px;
  }

  @include media-breakpoint-up(lg) {
    height: 1000px;
  }
}

.map-loader {
  width: 100px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}

.news-carousel {
  overflow-y: auto;
  padding: 0 50px;
  height: 500px;
}

.accordion-item {
  transition: all 0.3s ease; /* Transición suave */
}

.accordion-item:hover {
  transform: scale(1.05); /* Hace el elemento 20% más grande */
  margin: 5px 0 !important;
}

.mobile-navbar-brand {
  width: 100%;
}

.map-message {
    background-color: rgba(255,255,255,0.8);
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    z-index: 10;
}
