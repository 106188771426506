.masonry-with-rows {
  display: flex;
  flex-wrap: wrap;
  .masonry-container {
    height: 250px;
    max-height: 250px;
    overflow: hidden;
    background: white;
    margin: 0 1rem 1rem 0;
    flex: 1 0 auto;

    .work-img-bg,
    img {
      min-height: 250px;
      max-height: 250px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  @for $i from 1 through 100 {
    .masonry-container:nth-child(#{$i}) {
      $h: (random(400) + 70) + px;
      width: $h;
      min-width: 200px;
    }
  }
}
